<template>
  <v-card flat tile>
    <v-overlay :value="overlay" absolute>
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-toolbar flat color="white">
      <v-toolbar-title>
        <v-icon class="mb-1">mdi-airplane-edit</v-icon>
        RESERVA DE CARGA
      </v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-spacer></v-spacer>
      <v-text-field
        append-icon="mdi-magnify"
        v-model="buscar"
        dense
        label="filtrar"
        class="mx-4"
        flat
        clearable
      >
      </v-text-field>

      <v-spacer></v-spacer>
    </v-toolbar>
    <div>
      <v-data-table
        :headers="headerGuiasCoord"
        :items="guiasCoord"
        item-key="CLI_COORD"
        :search="buscar"
        :items-per-page="itemsPerPage"
        class="elevation-1"
        show-expand
        :single-expand="true"
        :expanded.sync="reservaexp"
      >
        <template v-slot:item.FECHA="{ item }">
          {{ formatoFecha(item.FECHA) }}
        </template>

        <template v-slot:item.CLIENTE="{ item }">
          {{ item.CLIENTE }} {{ item.MARCACION }}
        </template>

        <template v-slot:item.DEST_PAIS="{ item }">
          {{ item.DEST_NOMBRE }}
        </template>
        <template v-slot:item.AWB="{ item }">
          <span
            v-if="
              item.IDENT_BIENES != 'CONS' &&
              item.AWB.split('-')[0] != 'MAR' &&
              item.AWB.split('-')[0] != 'EZF' &&
              item.AWB.split('-')[0] != 'FWC'
            "
            >{{ item.AWB }}</span
          >
        </template>

        <template v-slot:item.VALOR_PARAM="{ item }">
          <v-chip
            v-if="item.IDENT_BIENES == 'CONS'"
            small
            class="ma-1"
            color="red"
            title="consolidación"
          >
            CONSOLIDADO
          </v-chip>
          <v-chip
            v-if="
              item.VALOR_PARAM != 'COORDINACION WEB' && item.VALOR_PARAM != null
            "
            small
            class="ma-1"
            :color="colorVariedad(item.VALOR_PARAM)"
          >
            {{ item.VALOR_PARAM }}
          </v-chip>
        </template>

        <template v-slot:item.opciones="{ item }">
          <v-btn color="success" icon small text @click="reservar(item)">
            <v-icon color="success">mdi-plus</v-icon>
            Reservar
          </v-btn>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-row>
              <v-col cols="12" class="my-2">
                <v-alert type="success" dense class="mb-0 py-1">
                  Datos de la reserva
                </v-alert>

                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center">Guia</th>
                        <th class="text-center">MAWB</th>
                        <th class="text-center">Cliente</th>
                        <th class="text-center">Piezas</th>
                        <th class="text-center">Full</th>
                        <th class="text-center">Medio</th>
                        <th class="text-center">Tercio</th>
                        <th class="text-center">Cuarto</th>
                        <th class="text-center">Sexto</th>
                        <th class="text-center">Octavo</th>
                        <th class="text-center">Diecis.</th>
                        <th class="text-center">DAE</th>
                        <th class="text-center">Opciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="reserva in item.reservas"
                        :key="reserva.NUM_GUIA_FINCA"
                      >
                        <td class="text-center">
                          {{ reserva.NUM_GUIA_FINCA }}
                        </td>
                        <td class="text-center">{{ reserva.AWB }}</td>
                        <td class="text-center">{{ reserva.SCLI_NOMBRE }}</td>
                        <td class="text-center">{{ reserva.PIEZAS_CONF }}</td>
                        <td class="text-center">
                          {{ parseFloat(reserva.FULL_RES).toFixed() }}
                        </td>
                        <td class="text-center">
                          {{ parseFloat(reserva.HB_RES).toFixed() }}
                        </td>
                        <td class="text-center">
                          {{ parseFloat(reserva.TR_RES).toFixed() }}
                        </td>
                        <td class="text-center">
                          {{ parseFloat(reserva.QT_RES).toFixed() }}
                        </td>
                        <td class="text-center">
                          {{ parseFloat(reserva.SX_RES).toFixed() }}
                        </td>
                        <td class="text-center">
                          {{ parseFloat(reserva.OC_RES).toFixed() }}
                        </td>
                        <td class="text-center">
                          {{ parseFloat(reserva.DS_RES).toFixed() }}
                        </td>
                        <td class="text-center">{{ reserva.DAE }}</td>
                        <td class="text-center">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                class="mr-1"
                                v-bind="attrs"
                                v-on="on"
                                @click="cargarReserva(reserva)"
                                color="info"
                                >mdi-eye</v-icon
                              >
                            </template>
                            <span>Editar reserva</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                class="ml-1"
                                v-bind="attrs"
                                v-on="on"
                                @click="cancelar(reserva)"
                                color="error"
                                >mdi-cancel</v-icon
                              >
                            </template>
                            <span>Cancelar reserva</span>
                          </v-tooltip>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </td>
        </template>
      </v-data-table>

      <!-- DIALOG RESERVA-->
      <v-dialog v-model="dialogReserva" persistent max-width="1260px">
        <v-card>
          <v-card-title>
            <v-icon class="mx-2">mdi-airplane-edit</v-icon>
            <span class="headline"
              >Reserva
              <span style="font-size: 11pt; font-weight: bold"
                >| {{ formatoFecha(reserva.FECHA) }}</span
              ></span
            >
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-form ref="formReserva">
                <v-row>
                  <v-col class="pt-1 pb-1" cols="12">
                    <v-autocomplete
                      :rules="_fechaDae"
                      :disabled="soloVer || esGuiaEncargos === false"
                      v-model="reserva.FUE_ID"
                      small
                      item-text="DAE_DEST"
                      item-value="FUE_ID"
                      :items="daes"
                      clearable
                      label="Seleccione una DAE"
                    >
                      <template v-slot:item="data">
                        <template>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="data.item.FUE"
                            ></v-list-item-title>
                            <v-list-item-subtitle
                              v-html="data.item.DESTINO"
                            ></v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>

                <v-row v-if="esConsolidado == true">
                  <v-col class="pt-0 pb-0" cols="9">
                    <v-autocomplete
                      :disabled="soloVer || esGuiaEncargos === false"
                      v-model="reserva.SCLIENTE_ID"
                      small
                      item-text="SCLI_NOMBRE"
                      item-value="ENTE_ID"
                      :items="subClientes"
                      clearable
                      label="Seleccione un cliente"
                    >
                      <template v-slot:item="data">
                        <template>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="data.item.SCLI_NOMBRE"
                            ></v-list-item-title>
                            <!-- <v-list-item-subtitle
                              v-html="data.item.DESTINO"
                            ></v-list-item-subtitle> -->
                          </v-list-item-content>
                        </template>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <div v-if="esConsolidado == false">
                  <!-- <v-row>
                  <v-col class="px-1 py-5" cols="14">
                    <v-label class="px-12 py-4">Variedades</v-label>
                    <span v-for="i in reserva.VARIEDADES">
                      <v-chip
                        small
                        class="ma-1"
                        :color="colorVariedad(i.VALOR_PARAM)"
                      >
                        {{ i.VALOR_PARAM }}
                      </v-chip>
                    </span>
                  </v-col>
                </v-row> -->

                  <v-row>
                    <v-col class="pt-0 pb-0" cols="1.5">
                      <v-text-field
                        :disabled="soloVer"
                        v-model="reserva.fulles"
                        label="Fulles"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col class="pt-0 pb-0" cols="1.5">
                      <v-text-field
                        :disabled="soloVer"
                        v-model="reserva.mitades"
                        label="Mitades"
                      >
                      </v-text-field>
                    </v-col>

                    <v-col class="pt-0 pb-0" cols="1.5">
                      <v-text-field
                        :disabled="soloVer"
                        v-model="reserva.tercios"
                        label="Tercios"
                      >
                      </v-text-field>
                    </v-col>

                    <v-col class="pt-0 pb-0" cols="1.5">
                      <v-text-field
                        :disabled="soloVer"
                        v-model="reserva.cuartos"
                        label="Cuartos"
                      >
                      </v-text-field>
                    </v-col>

                    <v-col class="pt-0 pb-0" cols="1.5">
                      <v-text-field
                        :disabled="soloVer"
                        v-model="reserva.sextos"
                        label="Sextos"
                      >
                      </v-text-field>
                    </v-col>

                    <v-col class="pt-0 pb-0" cols="1.5">
                      <v-text-field
                        :disabled="soloVer"
                        v-model="reserva.octavos"
                        label="Octavos"
                      >
                      </v-text-field>
                    </v-col>

                    <v-col class="pt-0 pb-0" cols="2">
                      <v-text-field
                        :disabled="soloVer"
                        v-model="reserva.dieciseisavos"
                        label="Dieciseisavos"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </div>

                <v-row>
                  <v-col class="pt-0 pb-0" cols="12">
                    <v-textarea
                      :disabled="soloVer"
                      rows="2"
                      v-model="reserva.OBSERVACIONES"
                      label="Observaciones"
                    >
                    </v-textarea>
                  </v-col>
                </v-row>

                <v-row v-if="esConsolidado == true">
                  <v-col cols="12">
                    <v-col
                      class="mt-4 mb-4"
                      style="border: 1px solid #ababab; border-style: dashed"
                    >
                      <v-btn
                        class="mx-2"
                        dark
                        small
                        @click="agregarClienteFinal()"
                        color="indigo"
                      >
                        <v-icon dark> mdi-plus </v-icon>
                        Agregar Item
                      </v-btn>
                    </v-col>
                    <v-form ref="formClienteFinal">
                      <v-data-table
                        :headers="headerClientesFinales"
                        :items="listaClientesFinales"
                        class="elevation-1 md-12"
                        hide-default-footer
                        :expanded.sync="reservaexp"
                      >
                        <template v-slot:item.opcion="{ item }">
                          <v-btn
                            class="mx-1 mb-1"
                            fab
                            x-small
                            dark
                            color="red"
                            @click="borrarClienteFinal(item)"
                          >
                            <v-icon dark> mdi-delete </v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:item.ID="{ item }">
                          {{ item.ID }}
                        </template>

                        <template v-slot:item.CLIENTE_FINAL_ID="{ item }">
                          <v-autocomplete
                            v-model="item.CLIENTE_FINAL_ID"
                            small
                            class="mx-1 mb-1"
                            item-text="CFINAL_NOMBRE"
                            item-value="ENTE_ID"
                            :items="cliFinales"
                            clearable
                            :rules="requiredRule"
                            label="Seleccione un cliente final"
                          >
                            <template v-slot:item="data">
                              <template>
                                <v-list-item-content>
                                  <v-list-item-title
                                    v-html="data.item.CFINAL_NOMBRE"
                                  ></v-list-item-title>
                                  <!-- <v-list-item-subtitle
                              v-html="data.item.DESTINO"
                            ></v-list-item-subtitle> -->
                                </v-list-item-content>
                              </template>
                            </template>
                          </v-autocomplete>
                        </template>

                        <template v-slot:item.PRODUCTO_ID="{ item }">
                          <v-autocomplete
                            v-model="item.PRODUCTO_ID"
                            small
                            class="mx-1 mb-1"
                            item-text="PRO_DESC"
                            item-value="PRO_ID"
                            :items="productos"
                            clearable
                            :rules="requiredRule"
                            label="Seleccione un producto"
                          >
                            <template v-slot:item="data">
                              <template>
                                <v-list-item-content>
                                  <v-list-item-title
                                    v-html="data.item.PRO_DESC"
                                  ></v-list-item-title>
                                  <!-- <v-list-item-subtitle
                              v-html="data.item.DESTINO"
                            ></v-list-item-subtitle> -->
                                </v-list-item-content>
                              </template>
                            </template>
                          </v-autocomplete>
                        </template>

                        <template v-slot:item.TCAJA_ID="{ item }">
                          <v-select
                            small
                            class="mx-1 mb-1"
                            v-model="item.TCAJA_ID"
                            :items="tiposCaja"
                            item-text="TCAJA_DESC"
                            item-value="TIPO_CAJA_ID"
                            label="Seleccione el tipo de caja"
                            :rules="requiredRule"
                            dense
                          >
                          </v-select>
                        </template>

                        <template v-slot:item.CAJAS_RES="{ item }">
                          <v-text-field
                            class="mx-1 mb-1"
                            dense
                            small
                            label="cantidad"
                            v-model="item.CAJAS_RES"
                            :rules="requiredRule"
                          >
                          </v-text-field>
                        </template>
                      </v-data-table>
                    </v-form>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-col class="text-center">
              <v-btn
                v-if="editarRes == false"
                color="info"
                small
                @click="guardarReserva()"
              >
                <v-icon>mdi-content-save</v-icon> Guardar
              </v-btn>
              &nbsp;

              <v-btn
                small
                v-if="
                  (editarRes == true && esConsolidado == false) ||
                  (editarRes == true && esConsolidado)
                "
                color="info"
                @click="modificarReserva()"
              >
                <v-icon>mdi-content-save</v-icon> Guardar
              </v-btn>
              &nbsp;
              <v-btn small @click="dialogReserva = false">
                <v-icon>mdi-cancel</v-icon> Cancelar
              </v-btn>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-card>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import moment from "moment";
import Vue from "vue";

export default {
  name: "ReservasFincaConsolidado",

  data: () => ({
    headerGuiasCoord: [
      { text: "Destino", align: "start", value: "DEST_PAIS" },
      { text: "Fecha de vuelo", align: "start", value: "FECHA" },
      { text: "Cliente", align: "start", value: "CLIENTE" },
      { text: "Guia", align: "start", value: "AWB" },
      { text: "Opciones", value: "VALOR_PARAM" },
      { text: "Estado", align: "start", value: "GUIA_ESTADO" },
      { text: "Reservas", value: "data-table-expand", align: "center" },
      { text: "Opciones", align: "start", value: "opciones" },
    ],
    headerClientesFinales: [
      { text: "Accion", align: "start", value: "opcion" },
      { text: "B", align: "start", value: "BORRADO" },
      { text: "ID", align: "start", value: "ID" },
      { text: "Cliente final", align: "start", value: "CLIENTE_FINAL_ID" },
      { text: "Producto", align: "start", value: "PRODUCTO_ID" },
      { text: "Tipo de caja", align: "start", value: "TCAJA_ID" },
      { text: "Cantidad", value: "CAJAS_RES" },
    ],
    clientesFinales: [],
    clientesFinales2: [],
    guiasCoord: [],
    destinos: [],
    daes: [],
    reserva: {
      IDENT_BIENES: "",
      RESERVA_ID: "",
      VARIEDADES: [],
      FUE_ID: "",
      FINCA_ID: "",
      HCOORD_ID: "",
      CNEE_ID: "",
      RATE: "",
      FECHA: "",
      COMER_ID: "",
      SCLIENTE_ID: "",
      full: "0",
      mitades: "0",
      tercios: "0",
      cuartos: "0",
      sextos: "0",
      octavos: "0",
      dieciseisavos: "0",
      OBSERVACIONES: "",
      AEROLINEA_ID: "",
      VALOR_PARAM: "",
      CANAL_ORIGEN: "",
    },
    reservaitem: {
      ID: "",
      RESERVA_ID: "",
      CLIENTE_FINAL_ID: "",
      TCAJA_ID: "",
      CAJAS_RES: 0,
      PRODUCT_ID: "",
      BORRADO: false,
    },
    reservaexp: [],

    buscar: "",
    destinoId: "",

    tiposCaja: [],
    cliFinales: [],
    subClientes: [],

    overlay: false,
    totalItems: 1,
    currentPage: 1,
    itemsPerPage: 50,
    dialogReserva: false,
    editarRes: false,
    soloVer: false,
    variedades: [
      { var: "ROSAS", color: "#ff3471" },
      { var: "FLOR DE VERANO", color: "#2267FF" },
    ],
    requiredRule: [
      (v) =>
        (v != "" && v != null && v != undefined) || "El campo es requerido",
    ],
  }),
  watch: {},
  computed: {
    ...mapState("master", ["user", "loadingTable"]),

    ...mapState("access", ["loggedIn"]),
    esConsolidado() {
      let res = false;
      if (this.reserva.IDENT_BIENES == "CONS") {
        res = true;
      }
      return res;
    },
    esGuiaEncargos() {
      let res = false;
      if (
        !(
          this.reserva.AEROLINEA_ID == 5957 ||
          this.reserva.AEROLINEA_ID == 5956 ||
          this.reserva.AEROLINEA_ID == 5953 ||
          this.reserva.AEROLINEA_ID == 5936 ||
          this.reserva.AEROLINEA_ID == 10655
        )
      )
        res = true;

      return res;
    },
    _fechaDae() {
      let rf = this.reserva.FECHA;

      if (this.reserva.FUE_ID == "" || this.reserva.FUE_ID == null) {
        if (
          !(
            this.reserva.AEROLINEA_ID == 5957 ||
            this.reserva.AEROLINEA_ID == 5956 ||
            this.reserva.AEROLINEA_ID == 5953 ||
            this.reserva.AEROLINEA_ID == 5936 ||
            this.reserva.AEROLINEA_ID == 10655
          )
        )
          return [false || "Seleccione una DAE "];
      }
      for (let i = 0; i < this.daes.length; i++) {
        if (this.daes[i].FUE_ID == this.reserva.FUE_ID) {
          if (
            !moment(rf).isBetween(
              moment(this.daes[i].FECHA_INICIO),
              moment(this.daes[i].FECHA_CADUCA),
              undefined,
              "[]"
            )
          ) {
            return [
              false ||
                "La DAE seleccionada no se puede utilizar para esta fecha de vuelo",
            ];
          }
        }
      }
    },
    listaClientesFinales() {
      let cf = [];

      for (let i = 0; i < this.clientesFinales.length; i++) {
        if (this.clientesFinales[i].BORRADO == null) {
          this.clientesFinales[i].BORRADO = false;
        }
        if (this.clientesFinales[i].BORRADO == false) {
          cf.push(this.clientesFinales[i]);
        }
      }
      return cf;
    },
  },
  methods: {
    ...mapMutations("master", [
      "setUrl",
      "setLoadingBtn",
      "setLoadingTable",
      "setTitleToolbar",
    ]),

    ...mapActions("master", ["requestApi", "alertNotification"]),

    handlePageChange(value) {
      //  this.currentPage = value;
      //this.getDestinos();
    },
    formatoFecha(fecha) {
      moment.locale("es");
      return moment(fecha).format("ddd D-MMM-Y");
    },
    colorVariedad(x) {
      for (let i = 0; i <= this.variedades.length; i++) {
        if (this.variedades[i].var == x) {
          return this.variedades[i].color;
        }
      }
    },
    getDestinos() {
      this.setLoadingTable(true);

      this.dataTable = [];

      this.setUrl("api/destinos-coord");
      this.requestApi({
        method: "GET",
        data: {
          page: this.currentPage,
        },
      })
        .then((res) => {
          this.destinos = res.data.destinos;
          this.daes = res.data.fues;
          console.log(this.daes);
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    reservar(item) {
      this.editarRes = false;
      //console.log(item.params_coord[0])
      this.reserva = {
        IDENT_BIENES: item.IDENT_BIENES,
        DESTINO_ID: item.DESTINO_ID,
        VARIEDADES: item.params_coord,
        FUE_ID: item.FUE_ID,
        FINCA_ID: this.user.empresa_id,
        HCOORD_ID: item.HCOORD_ID,
        CNEE_ID: item.CLIENTE_ID,
        FECHA: item.FECHA,
        COMER_ID: this.user.empresa_id,
        SCLIENTE_ID: this.esConsolidado == true ? "" : item.CLIENTE_ID,
        fulles: 0,
        mitades: 0,
        tercios: 0,
        cuartos: 0,
        sextos: 0,
        octavos: 0,
        dieciseisavos: 0,
        OBSERVACIONES: "",
        AEROLINEA_ID: item.AEROLINEA_ID,
        VALOR_PARAM: item.VALOR_PARAM,
      };
      this.clientesFinales = [];
      this.clientesFinales.push({
        RESERVA_ID: "",
        ID: "",
        CLIENTE_FINAL_ID: "",
        PRODUCTO_ID: "",
        TCAJA_ID: "",
        CAJAS_RES: "",
        BORRADO: false,
      });

      this.dialogReserva = true;
    },

    cargarReserva(item) {
      this.editarRes = true;
      this.soloVer = false;
      this.setUrl("api/cargar-reserva-cf");
      this.requestApi({
        method: "GET",
        data: {
          RESERVA_ID: item.RESERVA_ID,
        },
      })
        .then((res) => {
          //console.log(res.data.reserva);
          this.reserva = {
            IDENT_BIENES:
              res.data.reserva.CANAL_ORIGEN == "COORD_WEB_CONS" ? "CONS" : "FF",
            RESERVA_ID: res.data.reserva.RESERVA_ID,
            VARIEDADES: item.params_coord,
            FUE_ID: res.data.reserva.FUE_ID,
            FINCA_ID: res.data.reserva.FINCA_ID,
            HCOORD_ID: item.HCOORD_ID,
            CNEE_ID: res.data.reserva.CNEE_ID,
            FECHA: res.data.reserva.HCOORD_FECHA,
            COMER_ID: res.data.reserva.COMER_ID,
            SCLIENTE_ID: res.data.reserva.SCLIENTE_ID,
            fulles: res.data.reserva.FULL_RES,
            mitades: res.data.reserva.HB_RES,
            tercios: res.data.reserva.TR_RES,
            cuartos: res.data.reserva.QT_RES,
            sextos: res.data.reserva.SX_RES,
            octavos: res.data.reserva.OC_RES,
            dieciseisavos: res.data.reserva.DS_RES,
            OBSERVACIONES: res.data.reserva.OBSERVACIONES,
            AEROLINEA_ID: res.data.reserva.AEROLINEA_ID,
            ESTADO_RES: res.data.reserva.ESTADO_RES,
            CANAL_ORIGEN: res.data.reserva.CANAL_ORIGEN,
          };
          this.clientesFinales = res.data.items;

          this.dialogReserva = true;
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    cargarGuias() {
      if (this.destinoId == null) return;
      this.setLoadingTable(true);
      console.log(this.destinoId.DEST_CIUDAD);

      this.setUrl("api/guias-coord-consolidado");
      this.requestApi({
        method: "GET",
        data: {
          destino: this.destinoId.DEST_CIUDAD,
          page: this.currentPage,
        },
      })
        .then((res) => {
          this.guiasCoord = res.data.guias;
          this.tiposCaja = res.data.tiposCaja;
          this.productos = res.data.productos;
          this.cliFinales = res.data.cfinales;
          this.subClientes = res.data.subclientes;
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    guardarReserva() {
      if (!this.$refs.formReserva.validate()) {
        return;
      }

      if (this.esConsolidado) {
        if (!this.$refs.formClienteFinal.validate()) {
          return;
        }
      }

      this.dialogReserva = false;
      this.setUrl("api/crear-reserva-cf");
      this.requestApi({
        method: "POST",
        data: {
          reserva: this.reserva,
          items: this.clientesFinales,
          consolidado: this.esConsolidado,
        },
      })
        .then((res) => {
          if (res.data.error == true) {
            this.alertNotification({
              param: {
                title: "Error!",
                icon: "error",
                html: res.data.detail,
                confirmButtonColor: "#d60400",
                timer: 15000,
              },
            });
          } else {
            this.alertNotification({ param: { html: res.data.msg } });
          }
          this.cargarGuias();
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    modificarReserva() {
      if (!this.$refs.formReserva.validate()) {
        return;
      }

      this.dialogReserva = false;

      this.setUrl("api/modificar-reserva-cf");
      this.requestApi({
        method: "POST",
        data: {
          reserva: this.reserva,
          items: this.clientesFinales,
          consolidado: this.esConsolidado,
        },
      })
        .then((res) => {
          this.alertNotification({ param: { html: res.data.msg } });
          this.cargarGuias();
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    borrarClienteFinal(item) {
      if (item.ID == "") {
        for (var i = this.clientesFinales.length - 1; i >= 0; --i) {
          if (this.clientesFinales[i] == item) {
            this.clientesFinales.splice(i, 1);
          }
        }
      } else {
        item.BORRADO = true;
        //para forzar data-table refresh
        this.clientesFinales.splice(1, 0);
      }
    },
    agregarClienteFinal(item) {
      const res = {
        CLIENTE_FINAL_ID: "",
        TCAJA_ID: "",
        CAJAS_RES: 0,
        PRODUCTO_ID: "",
        ID: "",
        BORRADO: false,
      };
      this.clientesFinales.push(res);
    },

    cancelar(item) {
      Vue.swal({
        html: "Está seguro de cancelar esta reserva  ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed) {
          this.setUrl("api/cancelar-reserva-cf");
          this.requestApi({
            method: "POST",
            data: {
              RESERVA_ID: item.RESERVA_ID,
            },
          }).then((res) => {
            this.alertNotification({ param: { html: res.data.msg } });
            this.cargarGuias();
          });
        }
      });
    },
  },

  mounted() {
    document.title = "Sistema de carga";
    this.setTitleToolbar("RESERVA DE CARGA");
    this.getDestinos();
    this.cargarGuias();
  },
};
</script>
